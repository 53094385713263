import React from "react";

const LoadingScreen = () => {
  return (
    <>
      <div className="fixed left-0 flex justify-center items-center right-0 top-0 z-40 h-full w-full overflow-hidden bg-black-400/[.5]">
        <div className="loader"></div>
      </div>
    </>
  );
};

export default LoadingScreen;
