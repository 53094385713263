import React, { useEffect, useState } from "react";
import CancelIcon from "../../assets/images/close-icon.svg";

const InformationModel = ({ information, setShowInformationModel }) => {
  const [isVisible, setIsVisible] = useState(false);

  const animationStyles = {
    transform: isVisible ? "translateX(0)" : "translateX(-100%)",
    transition: "transform 0.4s ease-in-out",
  };

  const handleCloseButton = () => {
    setIsVisible(false);
    setTimeout(() => {
      setShowInformationModel(false);
    }, 400);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 100);
  }, []);

  return (
    <>
      <div
        className="fixed left-0 right-0 top-0 z-40 h-full w-full overflow-hidden bg-black-400/[.3]"
        style={animationStyles}
      >
        <div className="fixed left-0 right-0 top-[50%] -translate-y-2/4 px-3 sm:px-0 mx-auto shadow-xl shadow-slate-600 w-1/2 max-w-full rounded-2xl overflow-hidden bg-white">
          <div className="flex items-center justify-between bg-beige-600 pl-10 py-2 pr-2">
            <h2 className="text-lg text-white uppercase font-semibold">
              User: {" "}
              {information.details.name}
            </h2>

            <button
              type="button"
              className="close duration-200 hover:bg-yellow-700 rounded-lg p-2"
              onClick={() => handleCloseButton()}
            >
              <img
                alt="close"
                src={CancelIcon}
                className="h-[18px] w-[18px] pointer-events-none"
              />
            </button>
          </div>

          <div className="px-16 py-7 max-h-[70vh] overflow-y-auto">
            {typeof information.details === "object" && (
              <>
                <div className="flex flex-col space-y-2">
                  <div>
                    <b>ID:</b> {information.details._id}
                  </div>
                  <div>
                    <b>Name: </b>
                    {information.details.name}
                  </div>
                  <div>
                    <b>Email: </b>{information.details.email}
                  </div>
                  <div>
                    <b>Stripe Customer ID: </b>{information.details.customerID}
                  </div>
                  <div>
                    <b>Billing Address:</b>
                    <ul className="pl-6">
                      <li>
                        <b>Street 1: </b>
                        {information.details.billingAddress.street1}
                      </li>
                      <li>
                        <b>Street 2: </b>
                        {information.details.billingAddress.street2}
                      </li>
                      <li>
                        <b>City: </b>{information.details.billingAddress.city}
                      </li>
                      <li>
                        <b>State: </b>{information.details.billingAddress.state}
                      </li>
                      <li>
                        <b>Country: </b>
                        {information.details.billingAddress.country}
                      </li>
                      <li>
                        <b>Zipcode: </b>
                        {information.details.billingAddress.zipcode}
                      </li>
                    </ul>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default InformationModel;
