import React from "react";
import { memo } from "react";

const ErrorModel = ({ handleError, errorModel }) => {
  return (
    <>
      <div className="fixed left-0 right-0 top-0 z-40 h-full w-full overflow-hidden bg-black-400/[.5]">
        <div className="fixed left-0 right-0 top-[50%] -translate-y-2/4 px-3 sm:px-0">
          <div className="mx-auto shadow-xl shadow-slate-600 w-full max-w-full rounded-lg bg-white sm:max-w-[370px]">
            <div className="flex items-center justify-between rounded-t-lg bg-beige-400 px-5 py-3">
              <h2 className="text-base text-black-200 font-semibold">
                {errorModel.title}
              </h2>
            </div>

            <div className="px-5 py-7">
              <p className="text-sm text-black-200 text-center font-medium">
                {errorModel.message}
              </p>
            </div>

            <div className="flex items-center justify-around space-x-3 rounded-b-[10px] px-5 py-2">
              {errorModel.falseMessage && (
                <button
                  id="cancel"
                  onClick={() => handleError(false)}
                  className="flex duration-200 items-center justify-center h-[40px] min-w-[120px] text-[13px] text-black-200 font-medium px-2 border border-black-200 rounded hover:bg-black-200 hover:text-white"
                >
                  {errorModel.falseMessage}
                </button>
              )}
              <button
                id="checkOut"
                onClick={() => handleError(false)}
                className="flex items-center justify-center h-[40px] min-w-[120px] text-[13px] text-white font-medium px-2 bg-black-200 border border-black-200 rounded hover:bg-black-300 hover:text-white"
              >
                {errorModel.trueMessage}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ErrorModel.defaultProps = {
  errorModel: {
    title: "Error",
    message: "Some unknown error occured.",
    trueMessage: "Yes",
    falseMessage: "No",
  },
};

export default memo(ErrorModel);
