import React from "react";

const ToastMessage = ({ toastMessage }) => {
  return (
    <>
      <div className="absolute bottom-10 z-50 left-1/2 transform -translate-x-1/2 rounded-full py-2 px-12 text-center w-fit bg-green-400 border-[1px] border-green-700 text-black shadow-md font-semibold">
        {toastMessage}
      </div>
    </>
  );
};
export default ToastMessage;
