/** App styling */
import "./styles/App.css";

/** Routing */
import { RouterProvider, createBrowserRouter } from "react-router-dom";

/** Layout */
import Layout from "./components/layout/layout";

/** Components */
import PageNotFound from "./components/pageNotFound/pageNotFound";
import Login from "./components/login/login";
import Dashboard from "./components/dashboard/dashboard";

import { useState } from "react";
import Cookies from "js-cookie";
import PasswordChange from "./components/common/passwordChange";

function App() {
  const cookieValue = Cookies.get("token");
  const initialValues = {
    cookie: cookieValue,
    email: "",
    name: "",
  };

  const [state, setState] = useState(initialValues);

  /** Application routes */
  const routes = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      errorElement: <PageNotFound />,
      children: [
        {
          index: true,
          element: <Dashboard state={state} setState={setState} />,
        },
        { path: "login", element: <Login state={state} setState={setState} /> },
        {
          path: "dashboard",
          element: <Dashboard state={state} setState={setState} />,
        },
        {
          path: "change-password",
          element: <PasswordChange state={state} setState={setState} />,
        },
      ],
    },
  ]);
  return <RouterProvider router={routes} />;
}

export default App;
