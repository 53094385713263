import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/bway-headshots-white-logo.svg";
import editIcon from "../../assets/images/edit.svg";
import axios from "axios";
import Cookies from "js-cookie";
import InformationModel from "../common/userInformationModel";
import {
  ORDER_PAYMENT_VALUES,
  ORDER_STATUS_VALUES,
  PAGE_SIZE,
} from "../../services/constants";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import ErrorModel from "../common/errorModel";
import OrderStatusModel from "../common/orderStatusModel";
import ToastMessage from "../common/toastMessage";
const backendBaseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

const Dashboard = ({ state, setState }) => {
  const { cookie, name } = state;
  const [pageNo, setPageNo] = useState(1);
  const [itemList, setItemList] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(1);
  const [showInformationModel, setShowInformationModel] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showOrderStatus, setShowOrderStatus] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [userInformation, setUserInformation] = useState({});
  const [orderInformation, setOrderInformation] = useState({});
  const [errorInfo, setErrorInfo] = useState({
    title: "Error!",
    message: "",
    trueMessage: "OKAY",
    falseMessage: null,
  });
  const [filterSelections, setFilterSelections] = useState([]);
  const navigate = useNavigate();

  const handleLogout = () => {
    setState((prevState) => ({
      ...prevState,
      cookie: false,
    }));
    Cookies.remove("token");
    navigate("/login");
  };

  const handleEmailClick = async (type, value) => {
    const informationDetails = { type: type };
    try {
      const response = await axios.get(`${backendBaseUrl}/user`, {
        params: {
          attributeName: "email",
          attributeValue: value,
        },
      });
      informationDetails["details"] = response.data[0];
      setUserInformation(informationDetails);
      setShowInformationModel(true);
    } catch (error) {
      console.error(error);
      setErrorInfo({
        ...errorInfo,
        message: "Cannot get the user information. Try again after some time.",
      });
      setShowError(true);
    }
  };

  const getOrderList = (pageNo, silentAPICall) => {
    const token = Cookies.get("token");
    if (token) {
      if (!silentAPICall) setShowLoading(true);
      const combinedFilter =
        filterSelections.some((value) => ORDER_STATUS_VALUES.includes(value)) &&
        filterSelections.some((value) => ORDER_PAYMENT_VALUES.includes(value));
      axios
        .get(`${backendBaseUrl}/bo/orders`, {
          headers: { Authorization: token },
          params: {
            page: pageNo,
            limit: PAGE_SIZE,
            searchValue: searchValue,
            filterValues: filterSelections,
            combinedFilter: combinedFilter,
          },
        })
        .then(({ data }) => {
          if (!silentAPICall) setShowLoading(false);
          setItemList(data.data);
          setTotalItems(data.totalItems);
          if (data.totalItems === 0) setPageNo(0);
        })
        .catch((error) => {
          console.error(error);
          setErrorInfo({
            ...errorInfo,
            message: "Cannot get the Order list. Try again after some time.",
          });
          setShowError(true);
        });
    }
  };

  const handleOrderStatusChange = (changedStatus, item) => {
    const token = Cookies.get("token");
    if (!token) {
      return false;
    }

    if (changedStatus === 'Completed') {
      if (item.pickup === false) {
        setErrorInfo({
          ...errorInfo,
          title: "Error!",
          message: "Cannot change status to Completed for Shipping orders.",
        });
        setShowError(true);
        return false;
      }
    }
    if (changedStatus === 'Shipped') {
      if (item.pickup === true) {
        setErrorInfo({
          ...errorInfo,
          title: "Error!",
          message: "Cannot change status to Shipped for Pickup orders.",
        });
        setShowError(true);
        return false;
      }
    }

    return axios
      .post(
        `${backendBaseUrl}/bo/order-status`,
        {
          id: item._id,
          newStatus: changedStatus
        },
        { headers: { Authorization: token } }
      )
      .then(() => {
        getOrderList(pageNo, true);
        return true;
      })
      .catch((error) => {
        console.error(error);
        setErrorInfo({
          ...errorInfo,
          title: "Error!",
          message: "Cannot change order status. Try again after some time.",
        });
        setShowError(true);
        return false;
      });
  };

  const handleSearchSubmit = (e) => {
    if (e) e.preventDefault();
    setPageNo(1);
    getOrderList(1);
  };

  useEffect(() => {
    if (!cookie) {
      navigate("/login", { replace: true });
    }
  }, [navigate, cookie]);

  useEffect(() => {
    try {
      getOrderList(1);
      const token = Cookies.get("token");
      axios
        .get(
          `${backendBaseUrl}/bo/current-user`,
          {
            headers: { Authorization: token },
          }
        )
        .then(({ data: { name, email } }) => {
          setState((prevState) => ({ ...prevState, name: name, email: email }));
        })
        .catch((e) => console.error(e));

      // Set title of pagination buttons
      setTimeout(() => {
        const paginationLeftArrow =
          document.getElementsByClassName("rc-pagination-prev")?.[0];
        if (paginationLeftArrow) {
          paginationLeftArrow.title = "Previous Page";
        }

        const paginationRightArrow =
          document.getElementsByClassName("rc-pagination-next")?.[0];
        if (paginationRightArrow) {
          paginationRightArrow.title = "Next Page";
        }

        const jumpNext = document.getElementsByClassName(
          "rc-pagination-jump-next"
        )?.[0];
        if (jumpNext) {
          jumpNext.title = "Jump Next";
        }
      }, 100);
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleSearchSubmit();
    // eslint-disable-next-line
  }, [filterSelections]);

  return (
    <>
      {showInformationModel && (
        <InformationModel
          information={userInformation}
          setShowInformationModel={setShowInformationModel}
        />
      )}

      {showToast && <ToastMessage toastMessage={toastMessage} />}

      {showError && (
        <ErrorModel
          handleError={setShowError}
          errorModel={{
            title: errorInfo.title,
            message: errorInfo.message,
            cancelBox: errorInfo.cancelBox,
            trueMessage: errorInfo.trueMessage,
            falseMessage: errorInfo.falseMessage,
          }}
        />
      )}

      {showOrderStatus && (
        <OrderStatusModel
          orderInformation={orderInformation}
          setOrderInformation={setOrderInformation}
          setShowOrderStatus={setShowOrderStatus}
          handleOrderStatusChange={handleOrderStatusChange}
          setShowToast={setShowToast}
          setToastMessage={setToastMessage}
        />
      )}

      <div className="h-screen relative flex flex-col overflow-hidden bg-gray-50">
        <header className="bg-beige-600 px-8 py-1 shadow-md shadow-gray-400 flex justify-between items-center">
          <div className="flex items-center space-x-4">
            <img
              src={logo}
              alt="logo"
              className="h-16 cursor-pointer"
              onClick={() => window.location.reload()}
            />
            <div className="text-beige-100 text-4xl font-sans">Dashboard</div>
          </div>
          <div className="flex space-x-3 items-center">
            <div className="relative">
              <button
                title="Menu"
                onClick={() => setShowMenu(!showMenu)}
                className="text-white border text-xl font-sans active:scale-[1.03] px-3 py-1 bg-yellow-700 bg-opacity-50 font-medium shadow-md hover:shadow-yellow-700 duration-150 rounded-lg"
              >
                {name}
              </button>
              {showMenu && (
                <ul className="absolute bg-white p-2 rounded-md shadow-md mt-1 right-0 w-max">
                  <li
                    className="hover:bg-gray-200 rounded-md p-2 cursor-pointer"
                    onClick={() => navigate("/change-password")}
                  >
                    Change password
                  </li>
                </ul>
              )}
            </div>
            <button
              onClick={handleLogout}
              className="bg-red-600 hover:bg-red-700 text-white font-bold px-4 h-fit py-3 uppercase rounded focus:outline-none focus:shadow-outline"
            >
              Logout
            </button>
          </div>
        </header>
        <main
          className="pt-8 overflow-auto mb-8"
          onClick={() => setShowMenu(false)}
        >
          <div className="flex mb-7 justify-center">
            <div className="w-3/5 justify-end flex px-6">
              <form
                className="flex items-center w-full max-w-md"
                onSubmit={handleSearchSubmit}
              >
                <div className="relative w-full h-12">
                  <input
                    type="text"
                    id="simple-search"
                    className="bg-white border border-gray-300 h-full text-gray-900 text-sm rounded-l-full focus:ring-2 focus:outline-none focus:ring-blue-300 block w-full ps-5 p-2.5 pe-5"
                    placeholder="Search Email or Order number"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </div>
                <button
                  type="submit"
                  className="p-3 pr-4 mr-2 h-12 text-sm overflow-hidden font-medium text-white bg-blue-700 rounded-r-full border border-blue-700 hover:bg-blue-800 focus:ring-1 focus:outline-none focus:ring-blue-300"
                >
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </button>
              </form>
            </div>
            <div className="w-2/5 px-6 flex items-center justify-center">
              <label className="text-md font-sans font-semibold uppercase pr-4">
                Status:{" "}
              </label>
              <div>
                <div className="mb-2 w-fit mx-auto">
                  {ORDER_STATUS_VALUES.map((status, index) => (
                    <button
                      key={index}
                      onClick={() => {
                        const isPresent = filterSelections.includes(status);
                        setFilterSelections((prevSelections) =>
                          isPresent
                            ? prevSelections.filter((item) => item !== status)
                            : [...prevSelections, status]
                        );
                      }}
                      className={`${filterSelections.includes(status)
                          ? "bg-green-400 border-green-600 tick-button pl-4 hover:bg-green-600"
                          : "bg-gray-300 border-gray-400 hover:bg-gray-400"
                        } px-6 py-2 text-sm rounded-2xl font-mono text-md cursor-pointer  duration-200 m-1 border-[1px] hover:shadow-lg shadow-md hover:text-white hover:font-semibold`}
                    >
                      {status}
                    </button>
                  ))}
                </div>
                <hr />
                <div className="mt-2 w-fit mx-auto">
                  {ORDER_PAYMENT_VALUES.map((status, index) => (
                    <button
                      key={index}
                      onClick={() => {
                        const isPresent = filterSelections.includes(status);
                        setFilterSelections((prevSelections) =>
                          isPresent
                            ? prevSelections.filter((item) => item !== status)
                            : [...prevSelections, status]
                        );
                      }}
                      className={`${filterSelections.includes(status)
                          ? "bg-green-400 border-green-600 tick-button pl-4 hover:bg-green-600"
                          : "bg-gray-300 border-gray-400 hover:bg-gray-400"
                        } px-6 py-2 text-sm rounded-2xl font-mono text-md cursor-pointer  duration-200 m-1 border-[1px] hover:shadow-lg shadow-md hover:text-white hover:font-semibold`}
                    >
                      {status}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="shadow-lg rounded-lg overflow-hidden mx-4">
            {showLoading ? (
              <div className="loader mx-auto mb-10"></div>
            ) : (
              <div className="overflow-x-auto">
                <table className="min-w-full table-fixed">
                  <thead>
                    <tr className="bg-gray-200 text-sm">
                      <th className="w-1/12 bg-slate-500 text-white py-4 px-4 text-right font-bold"></th>
                      <th className="w-1/6 py-4 px-6 text-left text-gray-600 font-bold uppercase">
                        Order #
                      </th>
                      <th className="w-1/4 py-4 px-6 text-left text-gray-600 font-bold uppercase">
                        User Email
                      </th>
                      <th className="w-1/12 py-4 px-6 text-center text-gray-600 font-bold uppercase">
                        Status
                      </th>
                      <th className="w-1/4 py-4 px-6 text-center text-gray-600 font-bold uppercase">
                        Order Date
                      </th>
                      <th className="w-1/4 py-4 px-6 text-center text-gray-600 font-bold uppercase">
                        Shipping Date
                      </th>
                      <th className="w-1/4 py-4 px-6 text-center text-gray-600 font-bold uppercase">
                        ShipStation ID
                      </th>
                      <th className="w-1/6 py-4 px-6 text-center text-gray-600 font-bold uppercase">
                        Paper Type
                      </th>
                      <th className="w-[12%] py-4 px-6 text-center text-gray-600 font-bold uppercase">
                        Qty.
                      </th>
                      <th className="w-[12%] py-4 px-6 text-center text-gray-600 font-bold uppercase">
                        Rush
                      </th>
                      <th className="w-[12%] py-4 px-6 text-center text-gray-600 font-bold uppercase">
                        Pickup
                      </th>
                      <th className="w-[12%] py-4 px-6 text-center text-gray-600 font-bold uppercase">
                        Payment
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white text-sm">
                    {itemList.length === 0 ? (
                      <tr>
                        <td colSpan={12} className="text-center py-4 px-4">
                          No records found.
                        </td>
                      </tr>
                    ) : (
                      itemList.map((item, index) => (
                        <tr key={index} className="hover:bg-gray-100 bg-white">
                          <td className="py-4 bg-slate-400 text-white font-semibold text-right px-2 border-b border-gray-200">
                            {(pageNo - 1) * PAGE_SIZE + index + 1}
                          </td>
                          <td
                            className="hover:text-blue-700 duration-300 text-blue-400 active:scale-105 hover:cursor-pointer py-4 px-6 text-left border-b border-gray-200"
                            onClick={() => {
                              setOrderInformation(item);
                              setShowOrderStatus(true);
                            }}
                          >
                            {item.orderNumber ? item.orderNumber : "---"}
                            {item.draft ? " (Draft)" : ""}
                          </td>
                          <td
                            className="hover:text-blue-700 text-left text-blue-400 active:scale-105 hover:cursor-pointer duration-300 py-4 px-6 border-b border-gray-200"
                            onClick={() => handleEmailClick("User", item.email)}
                          >
                            {item.email ? item.email : "---"}
                          </td>
                          <td className="py-4 px-6 border-b border-gray-200 ">
                            <button
                              className="bg-gray-100 mx-auto px-3 py-2 flex active:scale-[1.03] space-x-2 hover:font-semibold items-center rounded-md shadow-md hover:bg-blue-400 cursor-pointer hover:text-white"
                              onClick={() => {
                                setOrderInformation(item);
                                setShowOrderStatus(true);
                              }}
                            >
                              <div className="flex space-x-2 justify-around px-1">
                                <div>{item.status}</div>
                                {item.productType !== "Digital" && (
                                  <img className="h-5" src={editIcon} alt="" />
                                )}
                              </div>
                            </button>
                          </td>
                          <td className="py-4 px-6 border-b text-center border-gray-200">
                            {item.orderCreationDate
                              ? item.orderCreationDate
                              : "---"}
                          </td>
                          <td className="py-4 px-6 border-b text-center border-gray-200">
                            {item.productType === "Digital" ? (
                              <>
                                {item.orderCreationDate}
                                <span className="text-beige-600 font-semibold">
                                  <br />
                                  {"(Digital Order)"}
                                </span>
                              </>
                            ) : item.shippingDate ? (
                              item.shippingDate
                            ) : (
                              "---"
                            )}
                          </td>
                          <td className="py-4 px-6 text-center border-b border-gray-200">
                            {item.shipstationId ? item.shipstationId : "---"}
                          </td>
                          <td className="py-4 px-6 text-center border-b border-gray-200">
                            {item.paperType ? item.paperType : "---"}
                          </td>
                          <td className="py-4 px-6 text-right pr-6 border-b border-gray-200 truncate">
                            {item.quantity ? item.quantity : "---"}
                          </td>
                          <td className="py-4 px-6 text-center border-b border-gray-200">
                            {item.productType === "Digital"
                              ? "---"
                              : item.expeditedShipping
                                ? "Yes"
                                : "No"}
                          </td>
                          <td className="py-4 px-6 border-b text-center border-gray-200">
                            {item.productType === "Digital"
                              ? "---"
                              : item.pickup
                                ? "Yes"
                                : "No"}
                          </td>
                          <td className="py-4 px-6 border-b text-center border-gray-200">
                            <span
                              className={`${item.payment === "Paid"
                                  ? "text-green-500"
                                  : item.payment === "Pending"
                                    ? "text-yellow-500"
                                    : "text-red-500"
                                } py-1 font-bold rounded-full`}
                            >
                              {item.payment}
                            </span>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div className="mt-5 p-4">
            <Pagination
              showTotal={(total, range) =>
                `Showing ${range[0]} -- ${range[1]} of ${total}`
              }
              current={pageNo}
              total={totalItems}
              align="center"
              pageSize={PAGE_SIZE}
              onChange={(newPage) => {
                setPageNo(newPage);
                getOrderList(newPage);

                // To change the title of the 3-dot button which appears only on specific cases
                setTimeout(() => {
                  const jumpPrev = document.getElementsByClassName(
                    "rc-pagination-jump-prev"
                  )?.[0];
                  if (jumpPrev) {
                    jumpPrev.title = "Jump Previous";
                  }
                }, 100);
              }}
              showSizeChanger={true}
            />
          </div>
        </main>
        <footer className="bg-beige-200 fixed bottom-0 w-screen text-sm text-center py-[2px] font-medium font-sans">
          Version: {process.env.REACT_APP_VERSION}{" "}
          {process.env.REACT_APP_VERSION_ENV_SUFFIX}
        </footer>
      </div>
    </>
  );
};

export default Dashboard;
