import Logo from "../../assets/images/bway-headshots-logo.png";
const PageNotFound = () => {
  return (
    <>
      <a href="/">
        <img src={Logo} alt="BWAY HEADSHOTS" className="absolute w-[200px] mx-auto" />
      </a>
      <div className="flex space-y-6 flex-col pb-14 justify-center items-center h-screen w-screen bg-beige-100">
        <label className="text-8xl font-mono font-extrabold">404</label>
        <div className="text-2xl text-center">
          <b>Oops!</b> The page you're looking for could not be found.
        </div>
        <a
          href="/"
          className="inline-flex items-center px-4 py-2 rounded-md text-base font-medium text-center text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Go to Home Page
        </a>
      </div>
    </>
  );
};

export default PageNotFound;
