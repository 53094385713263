import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import logo from "../../assets/images/bway-headshots-white-logo.svg";
import wave1 from "../../assets/images/wave1.svg";
import wave2 from "../../assets/images/wave2.svg";
import eyeOff from "../../assets/images/eye-off.svg";
import eyeOn from "../../assets/images/eye-on.svg";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "./loadingScreen";
import axios from "axios";
import ToastMessage from "./toastMessage";
const backendBaseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

const PasswordChange = ({ state, setState }) => {
  const { email } = state;
  const [showLoader, setShowLoader] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");

  const navigate = useNavigate();

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onErrorChanges = (message) => {
    setNewPassword("");
    setConfirmNewPassword("");
    setLoginErrorMessage(message);
  };

  const handleSubmit = (e) => {
    setLoginErrorMessage("");
    e.preventDefault();
    if (newPassword === "") {
      setLoginErrorMessage("Enter new password.");
      return;
    }
    if (confirmNewPassword === "") {
      setLoginErrorMessage("Enter new password again.");
      return;
    }
    if (newPassword.length < 6) {
      onErrorChanges("Password length is less than 6.");
      return;
    }
    if (newPassword !== confirmNewPassword) {
      onErrorChanges("Passwords do not match.");
      return;
    }
    try {
      setShowLoader(true);
      const token = Cookies.get("token");
      axios
        .post(
          `${backendBaseUrl}/bo/update-password`,
          {
            newPassword: newPassword,
          },
          {
            headers: { Authorization: token },
          }
        )
        .then((response) => {
          setShowLoader(false);
          if (response.status !== 200) {
            setLoginErrorMessage("Some error occurred");
          } else {
            if (response.data === true) {
              setShowToast(true);
              setTimeout(() => {
                setShowToast(false);
                navigate("/");
              }, 2000);
            } else {
              setLoginErrorMessage("Could not update your password");
            }
          }
        })
        .catch((e) => {
          setShowLoader(false);
          console.error(e);
          setNewPassword("");
          setConfirmNewPassword("");
          if (e.response) setLoginErrorMessage(e.response.data);
        })
        .finally(() => {
          setNewPassword("");
          setConfirmNewPassword("");
        });
    } catch (error) {
      setLoginErrorMessage("Some error occurred");
    }
  };

  useEffect(() => {
    const token = Cookies.get("token");
    if (!token || !email) navigate("/login");
  }, [navigate, email]);

  return (
    <>
      {showLoader && <LoadingScreen />}
      {showToast && (
        <ToastMessage toastMessage={"Password updated successfully."} />
      )}
      <div className="bg-beige-600 h-screen flex flex-col justify-center items-center">
        <button
          title="Back"
          className="absolute top-3 left-0 px-12 bg-yellow-800 text-white hover:scale-105 hover:shadow-md duration-150 rounded-r-xl text-4xl cursor-pointer z-10 hover:bg-yellow-900 hover:text-black"
          onClick={() => navigate("/")}
        >
          <span className="text-8xl leading-[0.5]"> &#8249;</span>
        </button>
        <img
          src={wave2}
          alt=""
          className="absolute top-0 opacity-10 -z-0 pointer-events-none"
        />
        <img
          src={wave1}
          alt=""
          className="absolute bottom-0 opacity-15 -z-0 pointer-events-none"
        />
        <img
          src={logo}
          alt=""
          className="absolute top-0 h-20 pointer-events-none"
        />
        <div className="bg-white z-10 px-10 pb-14 pt-8 rounded-2xl shadow-md shadow-amber-700 border-[1px] border-amber-900 w-10/12 md:w-1/2 lg:w-1/3">
          <h1 className="text-3xl font-sans uppercase font-bold mb-8 text-center">
            Change Password
          </h1>
          <form onSubmit={handleSubmit} noValidate>
            <div className="mb-4">
              <label
                className="block font-semibold text-gray-700 mb-2"
                htmlFor="email-2"
              >
                Email Address
              </label>
              <input
                className="border rounded w-full py-2 px-3  disabled:bg-gray-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email-2"
                type="email"
                value={email}
                disabled
                autoComplete="off"
              />
            </div>
            <div className="font-sans text-gray-500 text-sm mb-4">
              New password should be minimum of 6 characters
            </div>

            <div className="mb-4">
              <label
                className="block font-semibold text-gray-700 mb-2"
                htmlFor="new-password"
              >
                New Password
              </label>
              <input
                className="border rounded w-full py-2 pl-3 pr-10 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                id="new-password"
                type="password"
                name="change-password-new"
                value={newPassword}
                autoComplete="off"
                required
                onChange={(e) => {
                  setNewPassword(e.target.value);
                  setLoginErrorMessage("");
                }}
                placeholder="Enter new password"
              />
            </div>
            <div className="mb-4">
              <label
                className="block font-semibold text-gray-700 mb-2"
                htmlFor="confirm-password"
              >
                Confirm Password
              </label>
              <div className="relative">
                <input
                  className="border rounded w-full py-2 pl-3 pr-10 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="confirm-password"
                  name="change-password-confirm"
                  type={showPassword ? "text" : "password"}
                  value={confirmNewPassword}
                  required
                  autoComplete="off"
                  onChange={(e) => {
                    setConfirmNewPassword(e.target.value);
                    setLoginErrorMessage("");
                  }}
                  placeholder="Confirm your new password"
                />
                <button
                  type="button"
                  className="absolute w-6 inset-y-0 right-2 pb-2 focus:outline-none focus:scale-105"
                  onClick={handleTogglePasswordVisibility}
                >
                  {showPassword ? (
                    <img src={eyeOn} alt="eyeOn" />
                  ) : (
                    <img src={eyeOff} alt="eyeOff" />
                  )}
                </button>
              </div>
            </div>
            <div className="mb-6 flex justify-center">
              <button
                className="bg-blue-500 focus:outline-blue-700 hover:bg-blue-700 w-fit text-white font-bold py-3 px-3 uppercase rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Update password
              </button>
            </div>
            <div className="text-center text-red-600 bgre">
              {loginErrorMessage}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default PasswordChange;
