/** Routing */
import { Outlet } from "react-router-dom";

/** App Header and Footer */
// import AppHeader from "./header";
// import AppFooter from "./footer";

const AppLayout = () => {
  return (
    <>
      {/* <AppHeader /> */}
      <Outlet />
      {/* <AppFooter /> */}
    </>
  );
};

export default AppLayout;
